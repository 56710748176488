import { datadogRum } from "@datadog/browser-rum";
import { OktaAuth } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { getLocalStorageItem, setLocalStorageItem, useIndexedDB } from "best-common-react";
import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { getUserInfo, userLoggedIn } from "../api/MUTApi";
import config from "../config";
import { IndexedDbConstants } from "../constants/IndexDbConstants";
import { LocalStorageConstants } from "../constants/LocalStorageConstants";
import { MutUserInfo } from "../types/User";

type UserContext = {
  userInfo: MutUserInfo;
  accessToken: string;
  loggedIn: boolean;
  login: (data: MutUserInfo) => void;
  logout: () => void;
  permissionError: boolean;
  loginFunction: SetStateAction<void>;
  setPermissionError: Dispatch<SetStateAction<boolean>>;
  setLoginFunction: Dispatch<SetStateAction<void>>;
  getUserId(): number | undefined;
};

const UserContext = createContext<UserContext | undefined>(undefined);

const oktaAuth = new OktaAuth(config.oidc);

const UserProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { deleteAllData } = useIndexedDB();
  const [userInfo, setUserInfo] = useState<MutUserInfo | undefined>();
  const [accessToken, setAccessToken] = useState<string | undefined>();
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [permissionError, setPermissionError] = useState<boolean>(false);
  const [loginFunction, setLoginFunction] = useState(null);
  const { authState } = useOktaAuth();

  const getUserId = (): number | undefined => {
    return userInfo?.appUserId;
  };

  const login = async (): Promise<void> => {
    const localStorage: MutUserInfo | undefined = getLocalStorageItem<MutUserInfo>(LocalStorageConstants.USER_INFO);
    if (localStorage) {
      setUserInfo(localStorage);
      setLoggedIn(true);
    } else {
      const userInfoData: MutUserInfo = await getUserInfo();
      userLoggedIn();
      setLoggedIn(true);
      setUserInfo(userInfoData);
      setLocalStorageItem(LocalStorageConstants.USER_INFO, userInfoData);
    }
  };

  const logout = async () => {
    localStorage.clear();
    setAccessToken(undefined);
    setUserInfo(undefined);
    setLoggedIn(false);
    try {
      await deleteAllData(IndexedDbConstants.FILES_OBJECT_STORE);
    } catch (e) {
      console.error("Error clearing all mut files from DB");
    }
  };

  useEffect(() => {
    if (authState?.isAuthenticated) {
      login();
    }
  }, [authState]);

  useEffect(() => {
    if (!!userInfo) {
      datadogRum.setUser({
        id: userInfo.appUserId.toString(),
        email: userInfo.email,
        name: `${userInfo.lastName}, ${userInfo.firstName}`,
      });
    } else {
      datadogRum.removeUser();
    }
  }, [userInfo]);

  return (
    <UserContext.Provider
      value={{
        userInfo,
        accessToken,
        loggedIn,
        login,
        logout,
        permissionError,
        setPermissionError,
        loginFunction,
        setLoginFunction,
        getUserId,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUser = (): UserContext => {
  const userContext = useContext<UserContext | undefined>(UserContext);
  if (userContext === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }
  return userContext;
};

export { UserContext, UserProvider, useUser, oktaAuth };
